<template>
	<div class="page-content" style="min-height: 1900px;">
		<el-breadcrumb separator=">" style="position: absolute;margin-top: -44px;">
			<el-breadcrumb-item :to="{ path: '/index' }">Home</el-breadcrumb-item>
			<el-breadcrumb-item>Services</el-breadcrumb-item>
		</el-breadcrumb>
		<div style="text-align: justify;margin-top: 40px;">
			<p>
				The last few years have witnessed the ever-increasing output of scientific research results and the
				rapid development of digital publishing. Now scientific researchers and journal editors should be more
				active and proactive in allowing research findings to “go globally” and opening up the international
				market for academic exchanges, so as to promote increased academic exchanges.
			</p>
			<p style="margin-top: 1%;">
				As a leading international company offering professional journal service, we are constantly exploring
				how to help scientific researchers and journal editors to further promote research results and to expand
				markets in a brand new way. To this end, we provide diversified services to increase world influence,
				and enhance readership of published articles.
			</p>
		</div>
		<div style="margin-top: 5%;display: flex;">
			<div style="width: 60%;">
				<div
					style="padding: 20px;text-align: justify;line-height: 26px;background-image: linear-gradient(to right, #d7dbec , #ffffff);">
					<p>
						How publications adapt and survive in the global scientific community is an important issue that
						each practitioner in the publishing industry needs to cogitate. Due to long term exposure to
						such environment, we are able to accurately offer industry information and grasp the latest
						market trends. Based on the deep understanding of the global publishing industry over the years
						and the accumulated experience through long-term cooperation with internationally renowned
						publishers and journals, we can effectively promote content and significantly increase the
						influence of journals.
					</p>
					<p style="margin-top: 5%;">
						Journal development strategy will be offered such as promoting performance, increasing
						submissions, and imparting advanced publishing management concepts, so as to bring you more
						opportunities for international exchanges and cooperation.
					</p>
					<p style="margin-top: 5%;">
						The aim is to have Journals/Peridicals covered by Core Collections: Science Citation Index or
						Social Science Citation Index soon, not just indexed by Emerging Science Citation Index.
					</p>
				</div>
			</div>
			<div style="width: 35%;margin-left: 5%;text-align: right;">
				<p>
					<el-icon style="font-size: 30px; color: #fe7568;margin-right: 15px;">
						<Reading />
					</el-icon>
					<span
						style="font-family: Swiss721BT-Black;font-weight: bolder;font-size: 30px;-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-image: linear-gradient(to right, #fe7568 , #1a2f6d);">Journal
						Metrics</span>
				</p>
				<p style="font-size: 19px;margin-top: -20px;">International Strategic Consulting for
					Journals</p>
				<el-divider
					style="height: 3px;width: 13%;background-color: #fe7568;margin-left: 73%;margin-top: -10px;" />
				<el-image style="" :src="require('@/assets/images/service_01.png')"></el-image>
			</div>
		</div>
		<div style="margin-top: 5%;display: flex;">
			<div style="width: 35%;text-align: left;">
				<p>
					<span
						style="margin-right: 15px;font-family: Swiss721BT-Black;font-weight: bolder;font-size: 30px;-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-image: linear-gradient(to right, #fe7568 , #1a2f6d);">Content
						Services</span>
					<el-icon style="font-size: 30px; color: #fe7568;margin-right: 15px;">
						<Menu />
					</el-icon>
				</p>
				<p style="font-size: 19px;margin-top: -20px;">Digital Publishing Promotion and International Social
					Media
				</p>
				<el-divider
					style="height: 3px;width: 13%;background-color: #fe7568;margin-left: 42%;margin-top: -10px;" />
				<el-image style="" :src="require('@/assets/images/service_02.png')"></el-image>
			</div>
			<div style="width: 65%;">
				<div
					style="padding: 20px;text-align: justify;line-height: 26px;background-image: linear-gradient(to left, #d7dbec , #ffffff);">
					<p>
						As we all know, Journal Impact Factor is mainly determined by the number of citations, and the
						latter largely depends on the global popularity and the breadth of digital publishing of
						journals. In order to create a good brand image on a global scale, international promotion is a
						central part. Based on the characteristics and content of your journal, we will use appropriate
						methods and communication channels to provide technical support for your journal's global
						promotion strategy, which will play an essential role in coverage of journals by SCI/SSCI/AHCI.
					</p>
					<p style="margin-top: 5%;">
						Sciencealerts will provide a series of comprehensive international promotion services. You can
						enjoy a complete set of service agency support, or you can choose only a single service content,
						which also includes the production of distinctive dynamic content for you, such as graphical
						abstracts and video abstracts. This has gradually become a mainstream trend in the United States
						and Europe, and has effectively helped a large number of authors and journals increase the
						number of citations. Our editorial staff are very good at producing the dynamic content, which
						can better provide technical support for your journal.
					</p>
				</div>
			</div>
		</div>
		<div style="margin-top: 5%;display: flex;">
			<div style="width: 65%;">
				<div
					style="padding: 20px;text-align: justify;line-height: 26px;background-image: linear-gradient(to right, #d7dbec , #ffffff);height: 100%;">
					<p>
						In order to increase the exposure and popularity of the journal, we will provide you with
						efficient e-mail promotion services, which will promote your journal information to overseas
						markets, target readers and ensure a higher delivery rate through mature technology.
					</p>
					<p style="margin-top: 5%;">
						Meanwhile, English poster mailing services will be used to display the publications in libraries
						and academic institutions. We will contact potential authors by phone or communicate with
						relevant international conferences, and give direct feedback to journals and publishers. The
						marketing services will help you reach new audiences and readers and increase subscriptions of
						journals.
					</p>
				</div>
			</div>
			<div style="width: 35%;;text-align: right;">
				<p>
					<el-icon style="font-size: 30px; color: #fe7568;margin-right: 15px;">
						<Help />
					</el-icon>
					<span
						style="font-family: Swiss721BT-Black;font-weight: bolder;font-size: 30px;-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-image: linear-gradient(to right, #fe7568 , #1a2f6d);">Marketing
						Services</span>
				</p>
				<p style="font-size: 18px;color: #1a2f6d;margin-top: -20px;">Emailing, Telemarketing & Conference
					Support</p>
				<el-divider
					style="height: 3px;width: 13%;background-color: #fe7568;margin-left: 73%;margin-top: -10px;" />
				<el-image style="" :src="require('@/assets/images/service_03.png')"></el-image>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {

			};
		},

		methods: {},
		created() {

		},
		mounted() {

		},

	};
</script>

<style lang="scss" scoped>

</style>
